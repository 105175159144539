<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading")
    div.wrap-sign-ui.f.fh
      div.sign-ui
        div.wrap-title.f.fc.mb20
          img(src="/img/logo.svg")
        div.wrap-sign-in-ui.f.fc.mb12.flex-column
          div.btn.f.fc.mb12
            SignIn(:withConsent="withConsent" :googleSignInLabel="($route.name == 'sign-in') ? 'Googleでサインイン' : 'Googleでサインアップ'")
          //- div.btn.f.fc.mb12
          //-   MicrosoftSignIn(
          //-     :microsoftSignInLabel="($route.name == 'sign-in') ? 'Microsoftでサインイン' : 'Microsoftでサインアップ'")
          //- div.btn.f.fc
          //-   ZoomSignIn(
          //-     :zoomSignInLabel="($route.name == 'sign-in') ? 'Zoomでサインイン' : 'Zoomでサインアップ'")
        div.wrap-msg.mb30
          span.fz10.text-center.block.mb2 サインイン・サインアップすることであなたは当社の
          span.fz10.text-center.block 
            | #[a(href="https://www.meetawesome.jp/termsofservice" target="brank").pb1 利用規約]・
            | #[a(href="https://www.meetawesome.jp/privacypolicy" target="brank").pb1 プライバシーポリシー]
            | に同意したことになります
        div.wrap-select-sign-in-up.f.fc
          a(v-if="$route.name === 'sign-up'" @click="$router.push('/sign-in')").fz12 アカウントをお持ちの方はこちら
          a(v-if="$route.name === 'sign-in'" @click="$router.push('/sign-up')").fz12 アカウントをお持ちでない方はこちら
      div.bg.f.fh
        img(src="/img/hiro.jpg")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-sign-ui {
  width: 100%;
  height: 100vh;
  position: relative;
  .sign-ui {
    position: relative;
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    z-index: 1;
    top: 20%;
    .wrap-title {
      width: 100%;
    }
    .wrap-sign-in-ui {
      align-items: center;

      .btn {
        width: 260px;
      }
    }
    .wrap-msg,
    .wrap-select-sign-in-up {
      color: #fff;
      a {
        color: #fff;
        border-bottom: solid 0.4px rgba(255, 255, 255, 0.6);
      }
    }
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .wrap-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .overlay-content {
      width: 98%;
      max-width: $base_max_width_px;
      background: #fff;
      border-radius: 3px;
      overflow: hidden;
      .window-content {
        width: 96%;
        margin: 0 auto;
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapMutations: mapMutationsAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import SignIn from '@/components/sign-in/google-sign-in'
import MicrosoftSignIn from '@/components/sign-in/microsoft-sign-in'
import ZoomSignIn from '@/components/sign-in/zoom-sign-in'
import NowLoading from '@/components/shared/NowLoading'
const teamStore = createNamespacedHelpers('team')

export default {
  components: {
    Auth,
    SignIn,
    NowLoading,
    MicrosoftSignIn,
    ZoomSignIn
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      resultRedirect: null,
      redirectUrl: null,
      showNowLoading: true
      // showDialog: false
      // googleSignInLabel: ''
    }
  },
  computed: {
    ...mapStateAuth(['accessToken', 'isLoggedIn', 'uid']),
    withConsent() {
      const { withConsent } = this.$route.query
      return withConsent ? withConsent === 'true' : false
    }
  },
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    ...teamStore.mapActions(['loadTeamByUid']),
    onLoggedIn() {
      this.showNowLoading = false
      // this.$router.push('/home')
      let path = this.$route.params.redirectPath
        ? decodeURIComponent(this.$route.params.redirectPath)
        : '/calendar'
      this.$router.replace(path)
    },
    loginFailed() {
      this.showNowLoading = false
    },
    // async signInSuccess({ firebaseUser, token }) {
    //   console.log('signInSuccess')
    //   this.showNowLoading = false

    //   this.updateAccessToken(token.accessToken)

    //   // await this.sleepByPromise(0.8)
    //   // let firebaseUser = await firebase.auth().currentUser
    //   // console.log('firebaseUser:', firebaseUser)

    //   if (firebaseUser.uid) {
    //     this.showNowLoading = true

    //     let user = await firebase.auth().currentUser
    //     let userDoc = await database.userCollection().findById(firebaseUser.uid)

    //     if (!userDoc) {
    //       let userObj = {
    //         uid: user.uid,
    //         name: user.displayName ? user.displayName : user.email.split('@')[0],
    //         profile: 'No Profile',
    //         iconURL: user.photoURL,
    //         email: user.email,
    //         isAnonymous: false,
    //         lastSignInTime: user.metadata.lastSignInTime,
    //         createdAt: user.metadata.creationTime,
    //         signUpAt: new Date(),
    //         timeZone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Tokyo'
    //       }

    //       if (this.$route.params.invitedEmail) {
    //         userObj.invitedEmail = this.$route.params.invitedEmail
    //       }

    //       await database.userCollection().signUp(userObj, {
    //         idToken: token.idToken,
    //         accessToken: token.accessToken
    //       })
    //       // mixpannel
    //       identifyTrackedUser(firebaseUser.uid)
    //       signUpForTracking(userObj)
    //     } else {
    //       console.log(firebaseUser, {
    //         name: firebaseUser.displayName,
    //         iconURL: firebaseUser.photoURL,
    //         email: firebaseUser.email
    //       })
    //       await Promise.all([
    //         database.userCollection().setGoogleToken(firebaseUser.uid, {
    //           idToken: token.idToken,
    //           accessToken: token.accessToken,
    //           createdBy: firebaseUser.uid,
    //           createdAt: new Date()
    //         }),
    //         database.userCollection().updateUserInformation(firebaseUser.uid, {
    //           name: user.displayName || userDoc.name,
    //           iconURL: user.photoURL || userDoc.iconURL,
    //           email: user.email || userDoc.email,
    //           domain: user.email ? user.email.split('@').pop() : null
    //         })
    //       ])

    //       // mixpannel
    //       identifyTrackedUser(firebaseUser.uid)
    //     }

    //     // 再現できないエラーがあるので念のため例外処理を設定
    //     try {
    //       // アドレスを更新
    //       const { data: connections } = await getGoogleConnections(this.accessToken)
    //       if (connections) {
    //         const addressInFirestore = (this.memberInFirestoreContacts = await database
    //           .userCollection()
    //           .getAllContacts(firebaseUser.uid))
    //         const emailsInFirestore = addressInFirestore.map((e) => e.email)
    //         const adressInGoogleContact = connections.map((e) => {
    //           const email =
    //             e.emailAddresses && e.emailAddresses.length > 0
    //               ? e.emailAddresses[0].value
    //               : undefined
    //           const name =
    //             e.names && e.names.length > 0 && e.names[0].displayName
    //               ? e.names[0].displayName
    //               : email
    //           return { email, name, createdAt: new Date(), createdBy: firebaseUser.uid }
    //         })
    //         const newContacts = adressInGoogleContact
    //           .filter((e) => {
    //             return !emailsInFirestore.includes(e.email)
    //           })
    //           .filter((e) => {
    //             return e.email && e.name
    //           })
    //         if (newContacts[0]) {
    //           for (let i = 0; i < newContacts.length; i++) {
    //             database.userCollection().addMemberToContact(firebaseUser.uid, newContacts[i])
    //           }
    //         }
    //       }
    //     } catch (e) {
    //       console.log(e)
    //     }

    //     this.showNowLoading = false

    //     // eslint-disable-next-line
    //     let path = this.$route.params.redirectPath ? decodeURIComponent(this.$route.params.redirectPath) : '/home'
    //     if (this.$route.name === 'sign-up-with-plan') path = '/sign-up-with-plan'
    //     this.$router.push(path)

    //     setTimeout(() => {
    //       this.loadTeamByUid(firebaseUser.uid)
    //     }, 2000)
    //   }
    // },
    sleepByPromise(sec) {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000))
    }
  }
}
</script>
