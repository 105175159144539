<template lang="pug">
a(:href="authURL").btn-google.f.fm.px16.py10
  v-img(src="/icons/google.svg").mr12
  span.fz14 {{googleSignInLabel}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.btn-google {
  border-radius: 3px;
  background: $primary_bg_color;
  span {
    color: $secondary_text_color;
  }
}
</style>

<script>
import { scopes } from '@/components/utils/config'

export default {
  name: 'SignIn',
  props: {
    googleSignInLabel: {
      type: String,
      required: true
    },
    withConsent: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    authURL: null
  }),
  methods: {
    createAuthURL: ({ scope, redirectURL, clientId, state, withConsent }) => {
      let authURL = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${redirectURL}&client_id=${clientId}&state=${state}`
      authURL = withConsent ? authURL + '&prompt=consent' : authURL
      return authURL
    }
  },
  mounted() {
    let redirectURL
    let state
    if (this.$route.query.plan) {
      redirectURL = `${process.env.VUE_APP_DOMAIN}/auth-callback/${this.$route.query.plan}-plan`
      state = `${new Date().getTime()}-${this.$route.query.plan}-plan`
    } else {
      redirectURL = `${process.env.VUE_APP_DOMAIN}/auth-callback`
      state = '/calendar' // Default should be /calendar
      if (this.$route.params.redirectPath) {
        state = encodeURIComponent(this.$route.params.redirectPath)
      }
    }
    this.authURL = this.createAuthURL({
      scope: scopes.join(' '),
      redirectURL,
      clientId: process.env.VUE_APP_GOOGLE_CONFIG_CLIENT_ID,
      state,
      withConsent: this.withConsent
    })
  }
}
</script>
